// Health Form Hooks
import { useEffect, useState } from "react"
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefATOField, RefStoredMultipleValues, RefStoredNested } from "./StoreHooks";
import { filterObjectByFunction, isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";



// Watches all the store which is not ideal
export function SurveyProgress(surveyConfiguration, encoder) {

    const [totalQuestions, setTotalQuestions] = useState(null)
    const [totalAnswered, setTotalAnswered] = useState(null)

    const values = RefStoredMultipleValues(libCon.SURVEY_STRUCTURE in surveyConfiguration ? surveyConfiguration[libCon.SURVEY_STRUCTURE].map(ob => encoder(ob[libCon.SURVEY_QUESTION_ID])) : [])


    useEffect(() => {


        if (values != null && Object.keys(values).length > 0) {
            setTotalQuestions(Object.keys(values).length)
            setTotalAnswered(Object.values(values).filter(val => val !== null && val !== undefined).length)
        }
        else {
            setTotalQuestions(null)
            setTotalAnswered(null)
        }

    }, [values])


    return [totalQuestions, totalAnswered]

}


export const RefParticipantSurveyConfiguration = () => {
    const surveyStatus = RefATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_INTAKE_FORM_STATUS)
    const surveyConfiguration = RefSurveyConfiguration(libCon.PARTICIPANT_INTAKE_FORM, libCon.LATEST_PARTICIPANT_INTAKE_FORM, surveyStatus)

    return surveyConfiguration

}

export const RefHouseSurveyConfiguration = () => {
    const surveyStatus = RefATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_INTAKE_FORM_STATUS)
    const surveyConfiguration = RefSurveyConfiguration(libCon.HOUSE_INTAKE_FORM, libCon.LATEST_HOUSE_INTAKE_FORM, surveyStatus)

    return surveyConfiguration

}

export const RefSurveyConfiguration = (surveyName, generalConfiguration, surveyStatus) => {


    const pendingQuestions = RefStoredNested(locCon.PENDING_FORM_QUESTIONS_OBJECT, surveyName)

    const [finalConfiguration, setFinalConfiguration] = useState(structuredClone(libCon.EMPTY_FORM_CONFIGURATION))


    useEffect(() => {


        // If survey is pending forces the entire survey
        if (surveyStatus === libCon.ATF_PENDING) {
            setFinalConfiguration(generalConfiguration)
            return
        }

        let questions = pendingQuestions

        if (isNullOrUndefined(pendingQuestions))
            questions = {}

        questions = filterObjectByFunction(questions, i => i[libCon.ATF_STATUS] === libCon.ATF_PENDING)

        if (Object.keys(questions).length > 0) {
            let questionsIds = Object.values(questions).map(q => q[libCon.ATF_QUESTION_ID])


            // Filters Questions 
            let newConfiguration = structuredClone(generalConfiguration)
            newConfiguration[libCon.SURVEY_STRUCTURE] = newConfiguration[libCon.SURVEY_STRUCTURE].filter(q => questionsIds.includes(q[libCon.ID]))
            newConfiguration[libCon.SURVEY_METADATA][libCon.SURVEY_FILTERED] = true

            setFinalConfiguration(newConfiguration)

        }
        else {
            setFinalConfiguration(generalConfiguration)

        }




    }, [pendingQuestions, generalConfiguration, surveyStatus])


    return finalConfiguration


}
