import React, { useState } from "react";
import * as locCon from "../LocalConstants"
import { RefLanguage } from "../hooks/LanguageHooks";
import { Typography } from "antd";
import CustomButton from "../elements/CustomButton";
import { CopyOutlined } from "@ant-design/icons";
import { StateATOField, StateATOInsideSetField } from "../hooks/StoreHooks";
import * as locGenFun from "../utils/generalFunctions"
import { sleep } from "../community-hats-js-library/utils/generalFunctions";
import CustomSpin from "../elements/CustomSpin";


const { Text } = Typography;


function LocationExtractor({ latitude, setLatitude, longitude, setLongitude, accuracy, setAccuracy, enableCopy = true, onClickCleanUp = (lat, lon, acc) => true }) {


  const i18n = RefLanguage()


  // Extracting Location
  const [isExtractingLocation, setIsExtractingLocation] = useState(false)


  const getCurrentLocation = async () => {

    setIsExtractingLocation(true)

    await sleep(1000)

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {

          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          setAccuracy(position.coords.accuracy)

          setIsExtractingLocation(false)

          onClickCleanUp(position.coords.latitude, position.coords.longitude, position.coords.accuracy)

        }, (error) => {
          locGenFun.showError(i18n.t("locationExtractionError"))
        },
        { enableHighAccuracy: true });
    } else {
      console.log("Geolocation is not available in your browser.");
    }

  };


  return (

    <div className="verticalSection">

      {
        isExtractingLocation === true
          ? <CustomSpin type={locCon.BUTTON_SPINNER} />
          : <div style={{ width: "100%" }}><CustomButton type={locCon.PRIMARY_BUTTON} onClick={getCurrentLocation}>
            {i18n.t("getCurrentLocation")}
          </CustomButton></div>
      }
      <div style={styles.latLonContainer}>
        <div style={styles.coordinateContainer}>
          <Text style={styles.coordinateContainerTitle}>{i18n.t("latitude")}</Text>
          <Text>{latitude === null ? "----------" : latitude}</Text>
          {!enableCopy ? <div></div> : <CopyOutlined name="copy" style={{ color: "var(--primary-color-5)", fontSize: 24, marginTop: 8 }} onClick={() => latitude === null ? true : locGenFun.copyToClipboard(latitude, i18n.t("latitudeCopied"))} />}
        </div>
        <div style={styles.coordinateContainer}>
          <Text style={styles.coordinateContainerTitle}>{i18n.t("longitude")}</Text>
          <Text>{longitude === null ? "----------" : longitude}</Text>
          {!enableCopy ? <div></div> : <CopyOutlined name="copy" style={{ color: "var(--primary-color-5)", fontSize: 24, marginTop: 8 }} onClick={() => longitude === null ? true : locGenFun.copyToClipboard(longitude, i18n.t("longitudeCopied"))} />}
        </div>
        <div style={styles.coordinateContainer}>
          <Text style={styles.coordinateContainerTitle}>{i18n.t("error")} </Text>
          <Text>{accuracy === null ? "----------" : Math.round(accuracy)}</Text>
          {!enableCopy ? <div></div> : <CopyOutlined name="copy" style={{ color: "var(--primary-color-5)", fontSize: 24, marginTop: 8 }} onClick={() => accuracy === null ? true : locGenFun.copyToClipboard(Math.round(accuracy), i18n.t("accuracyCopied"))} />}
        </div>
      </div>
    </div>
  );
}



export function GenericLocationExtractor({ ...props }) {

  // Current Latitude and Longitude
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [accuracy, setAccuracy] = useState(null)

  return (<LocationExtractor latitude={latitude} setLatitude={setLatitude}
    longitude={longitude} setLongitude={setLongitude}
    accuracy={accuracy} setAccuracy={setAccuracy} {...props} />)

}



export function LocationExtractorFroATOFields({ atoId, latId, lonId, accId, ...props }) {

  // Current Latitude and Longitude
  const [latitude, setLatitude] = StateATOField(atoId, latId)
  const [longitude, setLongitude] = StateATOField(atoId, lonId)
  const [accuracy, setAccuracy] = StateATOField(atoId, accId)

  return (<LocationExtractor latitude={latitude} setLatitude={setLatitude}
    longitude={longitude} setLongitude={setLongitude}
    accuracy={accuracy} setAccuracy={setAccuracy} {...props} />)

}

export function LocationExtractorFroATOInsideSetFields({ positionId, atoId, latId, lonId, accId, ...props }) {


  // Current Latitude and Longitude
  const [latitude, setLatitude] = StateATOInsideSetField(positionId, atoId, latId)
  const [longitude, setLongitude] = StateATOInsideSetField(positionId, atoId, lonId)
  const [accuracy, setAccuracy] = StateATOInsideSetField(positionId, atoId, accId)

  return (<LocationExtractor latitude={latitude} setLatitude={setLatitude}
    longitude={longitude} setLongitude={setLongitude}
    accuracy={accuracy} setAccuracy={setAccuracy} {...props} />)

}



const styles = {

  latLonContainer: {

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    width: "100%"
  },
  coordinateContainer: {

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

  },
  coordinateContainerTitle: {
    fontSize: 21,

  },
};










