import React, { useEffect, useState } from "react";
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from "../components/Header";
import { RefLanguage } from "../hooks/LanguageHooks";
import { RefKeysOfATOSet, restartBundleDeployment, setStoredValue } from "../hooks/StoreHooks";
import CustomButton from "../elements/CustomButton";
import { ModalHook } from "../hooks/ModalHooks";
import { SimpleStoreCopyFieldFromATO } from "../elements/SimpleStoreCopyField";
import CheckboxGroup from "../elements/CheckboxGroup";
import { SyncATOButton } from "../elements/SyncATOButton";
import CustomText from "../elements/CustomText";
import { LocationExtractorFroATOFields } from "../components/LocationExtractor";
import SensorPlacementSetViewer from "../components/SensorPlacementSetViewer";
import { SimpleStoreCopySelectionFieldFromATO } from "../elements/SimpleStoreCopySelectionField";
import CompletionStatusText from "../elements/CompletionStatusText";
import { SimpleStoreCopyTextAreaFromATO } from "../elements/SimpleStoreCopyTextArea";
import { RefSetCurrentPage } from "../hooks/NavigationHooks";



export default function BundleDeploy() {


    const i18n = RefLanguage()

    // Current Page
    const setCurrentPage = RefSetCurrentPage()

    // Modal
    const [modal, openModal] = ModalHook()

    const restartProcess = () => {


        openModal(i18n.t("restartProcess"), i18n.t("restartProcessLong"), [
            {
                text: i18n.t("cancel"),
                onClick: () => false,
                type: locCon.MODULE_CANCEL_BUTTON,
            },
            {
                text: i18n.t("restart"),
                onClick: () => restartBundleDeployment(),
                type: locCon.MODULE_REGULAR_BUTTON
            },
        ]);
    }


    const keys = RefKeysOfATOSet()

    const [allCheckboxes, setAllCheckboxes] = useState([...locCon.BUNDLE_DEPLOYMENT_CHECKBOXES])

    useEffect(() => {

        let newCheckboxes = [...locCon.BUNDLE_DEPLOYMENT_CHECKBOXES,
        ...keys.map(positionId => locCon.getASHPCheckBoxId(positionId))]

        setAllCheckboxes(newCheckboxes)

    }, [keys])

    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>
                {modal}
                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("deployBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToDeployBundle")}</CustomText>
                <CustomButton type={locCon.DANGER_BUTTON} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButton>

                <div className="horizontalLine" />

                {/* Add Participant to database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("participantToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("participantToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.APT_ADD_PARTICIPANT_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("participantToDataBase"),
                    [libCon.CONTAINER]: <div>

                        <SimpleStoreCopySelectionFieldFromATO atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_TRADE} title={<div>{i18n.t("trade")}</div>} options={libCon.TRADES} optionsTextDict={Object.fromEntries(libCon.TRADES.map(k => [k, k]))} />
                        <SimpleStoreCopyFieldFromATO atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_NAME} title={i18n.t("participantFirstName")} placeHolder={i18n.t("participantFirstName")} />
                        <SimpleStoreCopyFieldFromATO atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_LAST_NAME} title={i18n.t("participantLastName")} placeHolder={i18n.t("participantLastName")} />
                        <SimpleStoreCopyFieldFromATO includeCheckbox={true} atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_PHONE_NUMBER} title={i18n.t("participantPhoneNumber")} placeHolder={i18n.t("participantPhoneNumber")} checkFunction={genFun.isValidPhoneNumber} defaultValue={libCon.DEFAULT_PHONE_NUMBER} checkboxText={i18n.t("phoneMissing")} />
                        <SimpleStoreCopyFieldFromATO includeCheckbox={true} atoId={locCon.AT_OBJECT_PARTICIPANT} fieldId={libCon.ATF_SEWA_ID} title={i18n.t("sewaId")} infoText={i18n.t("sewaIdText")} placeHolder={i18n.t("sewaId")} checkFunction={genFun.checkSewaId} defaultValue={libCon.MISSING} checkboxText={i18n.t("sewaIdCheckboxText")} />
                        <SyncATOButton atoId={locCon.AT_OBJECT_PARTICIPANT} atoBehavior={libCon.ATO_BEHAVIOR_PULL_CREATE} onClickCleanup={(val) => setStoredValue(locCon.APT_ADD_PARTICIPANT_TO_DATABASE, val)} />
                    </div>
                }} />

                <div className="horizontalLine" />

                {/* Add Phone placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("phonePlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("phonePlacementToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.APPD_PHONE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("phonePlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_PHONE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.APPD_PHONE_PLACEMENT, val)} />

                }}

                />

                <div className="horizontalLine" />

                {/* Add wearable placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("wearablePlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("wearablePlacementToDataBaseText")}</CustomText>
                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.AWP_WEARABLE_PLACEMENT,
                    [libCon.TEXT]: i18n.t("wearablePlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_WEARABLE_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.AWP_WEARABLE_PLACEMENT, val)} />
                }} />
                <div className="horizontalLine" />




                {/* Add house into database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("houseToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("houseToDataBaseText")}</CustomText>
                <CheckboxGroup
                    mainCheckbox={{
                        [libCon.ID]: locCon.ELC_EXTRACT_LOCATIONS_COORDINATES,
                        [libCon.TEXT]: i18n.t("extractLocationCoordinatesAndAdress"),
                        [libCon.CONTAINER]:
                            <div>
                                <SimpleStoreCopySelectionFieldFromATO atoId={locCon.AT_OBJECT_HOUSE} fieldId={libCon.ATF_AREA} title={<div>{i18n.t("area")}</div>} options={libCon.AREAS} optionsTextDict={Object.fromEntries(libCon.AREAS.map(k => [k, k]))} />
                                <SimpleStoreCopyTextAreaFromATO title={<div> {i18n.t("address")}</div>} atoId={locCon.AT_OBJECT_HOUSE} fieldId={libCon.ATF_ADDRESS} includeCheckbox={true} defaultValue={"Unknown"} checkboxText={i18n.t("adressUnkown")} />
                                <LocationExtractorFroATOFields atoId={locCon.AT_OBJECT_HOUSE} latId={libCon.ATF_LATITUDE} lonId={libCon.ATF_LONGITUDE} accId={libCon.ATF_ACCURACY} enableCopy={false} />

                            </div>
                    }}
                />

                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.ALD_ADD_LOCATION_TO_DATABASE,
                    [libCon.TEXT]: i18n.t("houseToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_HOUSE} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.ALD_ADD_LOCATION_TO_DATABASE, val)} />
                }} />

                <div className="horizontalLine" />

                {/* Add participant placement to Database*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("participantPlacementToDataBaseTitle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("participantPlacementToDataBaseText")}</CustomText>

                <CheckboxGroup disableClick={true} mainCheckbox={{
                    [libCon.ID]: locCon.APAR_PARTICIPANT_PLACEMENT,
                    [libCon.TEXT]: i18n.t("participantPlacementToDataBase"),
                    [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_PARTICIPANT_PLACEMENT} atoBehavior={libCon.ATO_BEHAVIOR_CREATE} onClickCleanup={(val) => setStoredValue(locCon.APAR_PARTICIPANT_PLACEMENT, val)} />
                }}
                />

                <div className="horizontalLine" />

                {/* Restart location Tracker App */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("locationTrackerApp")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("restartLocationTrackerAppText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.LTA_RESTART_TRACKING_APP, [libCon.TEXT]: i18n.t("restartTrackerApp") }} />
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.LTA_TURN_ON_LOCATION, [libCon.TEXT]: i18n.t("turnOnLocation") }} />

                <div className="horizontalLine" />


                {/* set up each one of the bundle sensors*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("deploySensors")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("deployBundleDevicesText")}</CustomText>
                <SensorPlacementSetViewer />
                <div className="horizontalLine" />

                {/* Participant Intake Form */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("participantIntakeForm")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("participantIntakeFormText")}</CustomText>

                <CheckboxGroup
                    mainCheckbox={{ [libCon.ID]: locCon.PIF_AMINISTER_INTAKE_FORM, [libCon.TEXT]: i18n.t("administerParticipantIntakeForm") }} />
                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => { setCurrentPage(locCon.PAGE_PARTICIPANT_INTAKE_FORM); setStoredValue(locCon.PIF_AMINISTER_INTAKE_FORM, true) }}>{i18n.t("administerParticipantIntakeForm")}</CustomButton>

                {/* House Intake Form */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("houseIntakeForm")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("houseIntakeFormText")}</CustomText>

                <CheckboxGroup
                    mainCheckbox={{ [libCon.ID]: locCon.HIF_AMINISTER_INTAKE_FORM, [libCon.TEXT]: i18n.t("administerHouseIntakeForm") }} />
                <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => { setCurrentPage(locCon.PAGE_HOUSE_INTAKE_FORM); setStoredValue(locCon.HIF_AMINISTER_INTAKE_FORM, true) }}>{i18n.t("administerHouseIntakeForm")}</CustomButton>



                <div className="horizontalLine" />


                {/* Replace Sim */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("insertSim")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("insertSimText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.IS_INSERT_SIM, [libCon.TEXT]: i18n.t("insertTheSim") }} />
                <div className="horizontalLine" />



                {/* Explain Duties */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("explainDuties")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("explainDutiesText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.ED_EXPLAIN_FITBIT, [libCon.TEXT]: i18n.t("explainFitbit") }} />
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.ED_EXPLAIN_DUTIES, [libCon.TEXT]: i18n.t("explainTheDuties") }} />
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.GD_SHOW_PHONE_PIN, [libCon.TEXT]: i18n.t("showPhonePin") }} />
                <div className="horizontalLine" />



                {/* Give Devices */}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("giveDevices")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("giveDevicesText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.GD_GIVE_DEVICES, [libCon.TEXT]: i18n.t("giveTheDevices") }} />
                <div className="horizontalLine" />


                {/* Collect Data and  Check bundel*/}
                <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("collectAndCheckBundle")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("collectAndCheckBundleText")}</CustomText>
                <CheckboxGroup mainCheckbox={{ [libCon.ID]: locCon.CC_COLLECT_DATA, [libCon.TEXT]: i18n.t("collectData") }} />
                <div className="horizontalLine" />


                <CompletionStatusText checkBoxIds={allCheckboxes} />

                <div className="horizontalLine" />



            </div>
        </div>);
}












